import React from 'react';
import { sha256 } from 'js-sha256';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'reactstrap';
import { withLocale } from '@dietlabs/components';
import Layout from 'Layout/Layout';

export class ShoppingCartThankYouComponent extends React.Component {
    componentDidMount() {
        // keitaro postback
        const matches = document.cookie.match(
            new RegExp(`(?:^|; )subid=([^;]*)`)
        );
        const subId = matches ? decodeURIComponent(matches[1]) : undefined;
        document.getElementById(
            'pb'
        ).src = `https://activerselabs.com/5fa50ed/postback?subid=${subId}&status=sale&payout=${this.props.paymentPrice.amount}&currency=pln`;
    }

    render() {
        return (
            <Layout page="thnk-you">
                {/* for keitaro (marketing) */}
                <img
                    id="pb"
                    height="1"
                    width="1"
                    style={{ display: 'none' }}
                    src=""
                />
                {/* div for marketing analitycs */}
                <div id="conversion_hash" className="d-none">
                    {sha256(this.props.userEmail)}
                </div>
                <section className="hero">
                    <Container>
                        <Row>
                            <Col>
                                <h1>
                                    {this.props.isPast48Hours ? (
                                        <>
                                            {this.props.t(
                                                'thank-you/title/48hours'
                                            )}
                                            <br />
                                            {this.props.t(
                                                'thank-you/title2/48hours'
                                            )}
                                        </>
                                    ) : (
                                        this.props.t('thank-you/title')
                                    )}
                                </h1>
                            </Col>
                        </Row>
                    </Container>
                </section>
                {this.props.children}
            </Layout>
        );
    }
}

ShoppingCartThankYouComponent.propTypes = {
    t: PropTypes.func.isRequired,
    userEmail: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
};

export default withLocale(ShoppingCartThankYouComponent);
